table td,
table th {
  text-align: left;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
}

table thead th {
  position: sticky;
  top: 0;
  background: white;
}
